import React from 'react';
import styled from "styled-components";
import house from '../../../../assets/icons/about/house.png';
import house_mobile from '../../../../assets/icons/about/house_mobile.png';
import house_mobile_brend from '../../../../assets/icons/about/house_mobile_brend.png';
import brend from '../../../../assets/icons/about/brend.svg';

import list_1 from '../../../../assets/icons/about/list_1.svg';
import list_2 from '../../../../assets/icons/about/list_2.svg';
import list_3 from '../../../../assets/icons/about/list_3.svg';
import list_4 from '../../../../assets/icons/about/list_4.svg';
import list_1_mobile from '../../../../assets/icons/about/list_1_mobile.png';
import list_2_mobile from '../../../../assets/icons/about/list_2_mobile.png';
import list_3_mobile from '../../../../assets/icons/about/list_3_mobile.png';
import list_4_mobile from '../../../../assets/icons/about/list_4_mobile.png';

const AboutParent_ = styled.div`
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;

  .green_back {
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 70px;
    background: var(--green);
  }

  .black_back {
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: #1D1D1D;
    z-index: -1;
  }

`;
const About_ = styled.div`
  margin: 30px 0 0 -20px;
  padding: 50px 0 20px 20px;
  background: #CBD8D3;
  width: calc(100% + 40px);
  transform: rotate(-1.8deg);

  .container {
    transform: rotate(1.8deg);
  }

  @media (max-width: 720px) {
    padding: 50px 35px 20px 35px;
  }
`;
const Title_ = styled.div`
  max-width: 485px;
  width: 100%;
  color: #315549;

  .title {
    margin-bottom: 20px;
    font-family: RfdBold, sans-serif;
    font-size: 38px;
    line-height: 46px;

    @media (max-width: 720px) {
      margin-bottom: 10px;
      font-size: 22px;
      line-height: 26px;
    }
  }

  .info {
    @media (max-width: 720px) {
      margin-bottom: 40px;
      font-size: 13px;
      line-height: 16px;
      color: #315549;
    }
  }
`;
const Image_ = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;
const List_ = styled.ul`
  display: flex;

  li {
    margin-right: 60px;
    width: fit-content;
    max-width: 270px;

    img {
      margin-bottom: 15px;
    }

    .label {
      margin-bottom: 10px;
      font-family: RfdBold, sans-serif;
      color: #1D1D1D;
      font-size: 22px;
      line-height: 26px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
const HouseBlockMobile_ = styled.div`
  margin-top: 65px;
  margin-left: -50px;
  padding-top: 120px;
  padding-bottom: 20px;
  position: relative;
  width: calc(100% + 70px);
  transform: rotate(-4deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(105.65deg, #315549 0%, #225040 102.64%);

  .house {
    position: absolute;
    top: -40px;
    transform: rotate(4deg);
  }

  .brend {
    transform: rotate(4deg);

  }
`;
const ListMobile_ = styled.ul`
  padding: 50px 0 0 0;

  li {
    margin-bottom: 25px;

    img {
      margin-bottom: 15px;
    }

    .label {
      margin-bottom: 10px;
      font-family: RfdBold, sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    .text {
      max-width: 345px;
      width: 100%;
      font-size: 13px;
      line-height: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const About = () => {
    const list = [
        {
            src: list_1,
            label: "Сила бренда",
            text: "Производственные мощности позволяют обеспечивать необходимые объемы " +
                "материалов для крупных объектов",
        },
        {
            src: list_2,
            label: "Скорость",
            text: "Нашу фирму отличает быстрое решение любых вопросов,налаженное" +
                " производство, опыт,надежность и ответственность",
        },
        {
            src: list_3,
            label: "Нам доверяют",
            text: "Благодаря нашей усердной работе нам доверяют более 500 000 клиентов",
        },
        {
            src: list_4,
            label: "Больше, лучше",
            text: "Более 5000 м2 собственных складов",
        }
    ];
    const listMobile = [
        {
            src: list_1_mobile,
            label: "Сила бренда",
            text: "Производственные мощности позволяют обеспечивать необходимые объемы " +
                "материалов для крупных объектов",
        },
        {
            src: list_2_mobile,
            label: "Скорость",
            text: "Нашу фирму отличает быстрое решение любых вопросов,налаженное" +
                " производство, опыт,надежность и ответственность",
        },
        {
            src: list_3_mobile,
            label: "Нам доверяют",
            text: "Благодаря нашей усердной работе нам доверяют более 500 000 клиентов",
        },
        {
            src: list_4_mobile,
            label: "Больше, лучше",
            text: "Более 5000 м2 собственных складов",
        }
    ];
    return (
        <AboutParent_>
            <div className="green_back"/>
            <About_>
                <div className="container">
                    <Title_>
                        <div className="title">
                            О компании
                        </div>
                        <div className="info">
                            Первый выпуск продукции был осуществлен в 1996 году.
                            На сегодняшний день наша организация предлагает
                            потребителям рынка строительных материалов широкий
                            ассортимент продукции собственного производства.
                        </div>
                    </Title_>
                    {window.innerWidth > 720 &&
                        <Image_>
                            <img src={house} alt="house"/>
                        </Image_>
                    }
                    {window.innerWidth < 720 &&
                        <HouseBlockMobile_>
                            <img
                                src={house_mobile}
                                className="house"
                                alt="house"
                            />
                            <div className="brend">
                                <img src={brend} alt="brend"/>
                            </div>
                        </HouseBlockMobile_>
                    }
                    {window.innerWidth > 720 &&
                        <List_>
                            {list.map((elem, indx) =>
                                <li key={elem.src}>
                                    <img src={elem.src} alt="icon"/>
                                    <div className="label">{elem.label}</div>
                                    <div className="text">{elem.text}</div>
                                </li>
                            )}
                        </List_>
                    }
                    {window.innerWidth < 720 &&
                        <ListMobile_>
                            {listMobile.map((elem, indx) =>
                                <li key={elem.src}>
                                    <img src={elem.src} alt="icon"/>
                                    <div className="label">{elem.label}</div>
                                    <div className="text">{elem.text}</div>
                                </li>
                            )}
                        </ListMobile_>
                    }

                </div>
            </About_>
            <div className="black_back"/>
        </AboutParent_>
    );
};

export default About;