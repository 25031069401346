import React from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const SliderItem_ = styled.div`
  margin-right: 20px;
  max-width: 420px;
  min-width: 420px;
  width: 100%;
  cursor: pointer;

  .preview_image {
    margin-bottom: 20px;
    height: 300px;
    max-width: 410px;
    width: 100%;
    overflow: hidden;
    border-radius: 24px;

    img {
      min-height: 300px;
      min-width: 420px;
    }
  }

  .title {
    margin-bottom: 10px;
    font-family: RfdBold, sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: var(--green);
  }

  .text {
    color: var(--text_green);
  }

  @media (max-width: 1620px) {
    max-width: 320px;
    min-width: 320px;
    .preview_image {
      min-width: 320px;
    }
  }
  @media (max-width: 728px) {
    margin: 0 auto;
  }
`;
const SliderItem = ({src, title, text, slug}) => {
    const navigation = useNavigate();

    return (
        <SliderItem_ onClick={() => navigation(`/news-page/${slug}`)}>
            <div className="preview_image">
                <img src={src} alt="slider_img"/>
            </div>
            <div className="title">{title}</div>
            <div className="text">{text}</div>
        </SliderItem_>
    );
};

export default SliderItem;