import React, { useRef, useState, useEffect } from "react"
import { Canvas } from "@react-three/fiber"
import { useGLTF, Environment, OrbitControls } from "@react-three/drei"
import { proxy, useSnapshot } from "valtio"
import styled, { css } from "styled-components";
import arrow from "../../../../assets/icons/calculate/arrow.svg";
import hand from "../../../../assets/icons/house_model/hand.svg";

const HouseSlide_ = styled.div`
  padding-top: 30px;
  width: 100%;
  position: relative;
  /*overflow: hidden;*/
  background: var(--text_green);

  .top_black {
    position: absolute;
    width: calc(100% + 30px);
    height: 60px;
    top: -27px;
    left: -10px;
    transform: rotate(-1.6deg);
    background: #171717;
    z-index: 0;
  }

  .bottom_green {
    position: absolute;
    width: calc(100% + 30px);
    height: 500px;
    bottom: -130px;
    left: -10px;
    transform: rotate(-4deg);
    background: var(--green);
    z-index: 0;
  }


  @media (max-width: 920px) {
    padding: 30px 15px 0 15px;
  }
`;
const Canvas_ = styled.div`
  margin: 0 auto;
  padding-right: 80px;
  width: 100%;
  max-width: 1240px;
  position: relative;
  z-index: 1;

  canvas {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background: transparent;
  }
  @media (max-width: 920px) {
    padding-right: 40px;
  
  }
  @media (max-width: 720px) {
    padding-right: 0;
    padding-left: 0;
    canvas {
      width: 500px !important;
      height: 500px !important;
    }
  }
  @media (max-width: 520px) {
    padding-left: 0;
    canvas {
      width: 400px !important;
      height: 400px !important;
    }
  }
  @media (max-width: 420px) {
    padding-left: 0;
    canvas {
      transform: translateX(-10px);
      width: 350px !important;
      height: 350px !important;
    }
  }
  @media (max-width: 370px) {
    padding-left: 0;
    canvas {
      transform: translateX(-20px);
      width: 330px !important;
      height: 330px !important;
    }
  }
`;
const H1_ = styled.div`
  margin: 50px auto 20px auto;
  max-width: 880px;
  width: 100%;
  font-family: RfdBold, sans-serif;
  font-weight: 700;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: white;

  @media (max-width: 920px) {
    font-size: 28px;
    line-height: 34px;
  }

`;
const SelectColor_ = styled.div`
  margin: -27px auto 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 960px;
  width: 100%;

  .hand_move {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    transition: all .5s linear;

    img {
      width: 50px;
      height: 50px;
      animation-name: slidein;
      animation-duration: 1.5s;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes slidein {
      0% {
        transform: translateX(-30px);
      }
      50% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(30px);
      }
    }

  }

  ${props => props.hideHand && css`
    .hand_move {
      opacity: 0;
      visibility: hidden;
    }
  `};

  @media (max-width: 920px) {
    flex-direction: column;
  }
`;
const SelectParent_ = styled.div`
  padding: 12.5px 13.5px 14.5px 13.5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 470px;
  border: 1px solid #92B0A6;
  border-radius: 16px;
  transition: all .3s linear;
  user-select: none;
  cursor: pointer;
  z-index: 1;

  .label {
    margin-top: 2px;
    width: fit-content;
    white-space: nowrap;
    color: var(--text_green);

  }

  .border {
    margin: 0 10px;
    width: 1px;
    height: 20px;
    background: var(--text_green);
  }

  .value {
    margin-top: 2px;
    color: white;
    display: flex;
    align-items: center;
    min-width: 35px;
    text-align: end;
    white-space: nowrap;

    span {
      height: 14px;
    }

    .arrow {
      margin-left: 15px;
    }
  }

  .select_drop {
    position: absolute;
    width: calc(100% + 2px);
    top: 48px;
    left: -1px;
    max-height: 1px;
    opacity: 0;
    visibility: hidden;
    /*background: rgba(146, 176, 166, 0.2);*/
    border: 1px solid #92B0A6;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(7.5px);
    border-top-color: transparent;
    /*backdrop-filter: blur(2px);*/
    border-radius: 0 0 16px 16px;
    transition: all .3s linear;
    overflow-y: auto;

    div {
      padding: 15px;
      color: var(--text_green);
      border-bottom: 1px solid rgba(146, 176, 166, 0.2);
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 19px;
      transition: all .3s linear;
      cursor: pointer;

      span {
        margin: 5px 0 0 15px;
      }

      &:hover {
        color: #F74F38;
      }

      &.active {
        color: white;
      }
    }

    @media (max-width: 920px) {
      background: rgba(0, 0, 0, 1);
    }
    @media (max-width: 520px) {
      top: 41px;
    }
  }

  &:not(:last-child) {
    margin-right: 20px
  }

  ${(props) => props.open && css`
    border-bottom-color: transparent;
    border-radius: 16px 16px 0 0;
    background: rgba(146, 176, 166, 0.2);
    z-index: 2;

    .select_drop {
      max-height: 275px;
      opacity: 1;
      visibility: initial;
      z-index: 3;
    }
  `};

  @media (max-width: 920px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 520px) {
    flex-direction: column;
    .border {
      display: none;
    }

    .label {
      padding-bottom: 7px;
      margin-bottom: 7px;
      width: 100%;
      max-width: 100%;
      text-align: center;
      border-bottom: 1px solid var(--text_green);
    }
  }
`;
const ColorFigure_ = styled.div`
  margin-right: 5px;
  padding: 0 !important;
  width: 20px;
  height: 20px;
  border: 1px solid #FFFFFF !important;
  border-radius: 6px;

  ${(props) => props?.colorFigure && css`
    background: ${props.colorFigure};
  `}
`;

const Shoe = ({state, setHideHand}) => {
    const ref = useRef()
    const snap = useSnapshot(state)
    const {nodes, materials} = useGLTF("Home_21.glb")
    const [hovered, set] = useState(null)

    useEffect(() => {
        if (hovered && ref?.current) {
            document.body.style.cursor = `grab`
            return () => (document.body.style.cursor = `auto`)
        }
    }, [hovered])
    return (
        <>
            <group
                ref={ref}
                onPointerOver={(e) => {
                    e.stopPropagation()
                    set(e.object.material.name)
                    setTimeout(() => setHideHand(true), 3500)
                }}
                onPointerOut={(e) => e.intersections.length === 0 && set(null)}
                onPointerMissed={() => (state.current = null)}
                onClick={(e) => {
                    e.stopPropagation()
                    /*if (e.object.material.name)
                        state.current = e.object.material.name*/
                }}
            >
                {nodes?.Scene?.children.map((elem, indx) => {
                        let matherial = "";
                        let position = [];
                        let rotation = [];
                        let scale = 1;

                        switch (elem.name) {
                            case "Cube": {
                                matherial = "Material.001";
                                break;
                            }
                            case "Cube001": {
                                matherial = "Material.008";
                                position = [0.0, -0.0, 0.0];
                                break;
                            }
                            case "Cube002": {
                                matherial = "Material.009";
                                position = [2.27, -2.15, -1.95];
                                /*scale = 0.8;*/
                                break;
                            }
                            case "Cube003": {
                                matherial = "Material.010";
                                position = [3.6, -0.65, 2.1];
                                scale = 0.8;
                                break;
                            }
                            case "Cube004": {
                                matherial = "Material.010";
                                position = [6.0, -0.65, 2.1];
                                scale = 0.8;
                                break;
                            }
                            case "Cube005": {
                                matherial = "Material.010";
                                position = [7.3, -0.65, 0];
                                rotation = [0, 1.55, 0]
                                scale = 0.8;
                                break;
                            }
                            case "Cube006": {
                                matherial = "Material.010";
                                position = [7.3, -0.65, -4.0];
                                rotation = [0, 1.55, 0]
                                scale = 0.8;
                                break;
                            }
                            case "Cube007": {
                                matherial = "Material.010";
                                position = [-0.2, 1.5, 5.1];
                                rotation = [0, 0, 1.57]
                                scale = 0.5;
                                break;
                            }
                            case "Cube008": {
                                matherial = "Material.010";
                                position = [-6.8, -0.65, 0.5];
                                rotation = [0, -1.57, 0]
                                scale = 0.8;
                                break;
                            }
                            case "Cube009": {
                                matherial = "Material.010";
                                position = [-6.8, -0.65, -4.5];
                                rotation = [0, -1.57, 0]
                                scale = 0.8;
                                break;
                            }
                            case "Cube010": {
                                matherial = "Material.010";
                                position = [5.5, -0.65, -6.0];
                                rotation = [0, 3.15, 0]
                                scale = 0.8;
                                break;
                            }
                            case "Cube011": {
                                matherial = "Material.010";
                                position = [2.0, -0.65, -6.0];
                                rotation = [0, 3.15, 0]
                                scale = 0.8;
                                break;
                            }
                            case "Cube012": {
                                matherial = "Material.010";
                                position = [-1.5, -0.65, -6.0];
                                rotation = [0, 3.15, 0]
                                scale = 0.8;
                                break;
                            }
                            case "Cube013": {
                                matherial = "Material.010";
                                position = [-5.0, -0.65, -6.0];
                                rotation = [0, 3.15, 0]
                                scale = 0.8;
                                break;
                            }
                            case "Cube014": {
                                matherial = "Material.010";
                                position = [-4.66, -0.65, 2.1];
                                scale = 0.8;
                                break;
                            }
                            case "Cube029": {
                                matherial = "Door";
                                position = [2.25, -2.12, -2.0];
                                break;
                            }

                        }
                        if (elem.name)
                            return (
                                <mesh
                                    key={indx}
                                    receiveShadow={true}
                                    castShadow={true}
                                    geometry={elem.geometry}
                                    material={materials[matherial]}
                                    material-color={snap.items[matherial]}
                                    position={position.length > 0 ? position : ""}
                                    rotation={rotation.length > 0 ? rotation : [0, 0, 0]}
                                    scale={scale}
                                />
                            )
                    }
                )}
            </group>
        </>
    )
};
const HouseSlide = () => {
    const [valueWall, setValueWall] = useState({label: "RAL-1014 темно-бежевый", value: "#DED09F"});
    const [valueRoof, setValueRoof] = useState({label: "RAL-3003 рубин", value: "#8D1D2C"});
    const [flagOpenRoof, setFlagOpenRoof] = useState(false);
    const [flagOpenWall, setFlagOpenWall] = useState(false);
    const [hideHand, setHideHand] = useState(false);
    const [colorsList] = useState([
        {label: "RAL-1015 бежевый", value: "#e6d2b5"},
        {label: "RAL-1018 желтый", value: "#faca30"},
        {label: "RAL-2004 оранжевый", value: "#e25303"},
        {label: "RAL-3003 рубин", value: "#861a22"},
        {label: "RAL-3005 красное вино", value: "#59191f"},
        {label: "RAL-5005 синий", value: "#005387"},
        {label: "RAL-5021 морская волна", value: "#007577"},
        {label: "RAL-6002 зеленая листва", value: "#325928"},
        {label: "RAL-6005 зеленый мох", value: "#114232"},
        {label: "RAL-7004 светло-серый", value: "#9b9b9b"},
        {label: "RAL-7024 темно-серый", value: "#45494e"},
        {label: "RAL-8017 шоколад", value: "#442f29"},
        {label: "RAL-9003 белый", value: "#ecece7"},
        {label: "RAL-9006 серебристый металик", value: "#a1a1a0"}
    ]);

    const state = proxy({
        items: {
            "Material.008": valueWall.value, // фасад
            "Material.009": valueRoof.value, // крыша
        }
    })

    useEffect(() => {
        state.items["Material.001"] = valueWall.value;
    }, [valueWall])
    useEffect(() => {
        state.items["Material.002"] = valueRoof.value;
    }, [valueRoof])

    return (
        <HouseSlide_>
            <div className="top_black"/>
            <H1_>
                Приятно смотреть на красивый дом подобрав правильно цвета
            </H1_>
            <Canvas_>
                <Canvas camera={{position: [0, 0, 25], fov: 40}}>
                    <Shoe state={state} setHideHand={setHideHand}/>
                    <Environment preset="warehouse"/>
                    <OrbitControls maxPolarAngle={Math.PI / 2.4} enableZoom={false} enablePan={false}/>
                </Canvas>
            </Canvas_>
            <SelectColor_ hideHand={hideHand}>
                <SelectParent_
                    open={flagOpenRoof}
                    onClick={() => setFlagOpenRoof(!flagOpenRoof)}
                    onBlur={() => setFlagOpenRoof(false)}
                    tabIndex={0}
                >
                    <span className="label">Цвет кровли</span>
                    <div className="border"/>
                    <div className="value">
                        <ColorFigure_ colorFigure={valueRoof.value}/>
                        <span>{valueRoof.label}</span>
                        <img src={arrow} className="arrow" alt="arrow"/>
                    </div>
                    <div className={"select_drop"}>
                        {colorsList.map((elem) =>
                            <div
                                className={elem === valueRoof ? "active" : ""}
                                onClick={() => setValueRoof(elem)}
                                key={elem.value}
                            >
                                <ColorFigure_ colorFigure={elem.value}/>
                                <span>{elem.label}</span>
                            </div>
                        )}
                    </div>
                </SelectParent_>
                <SelectParent_
                    open={flagOpenWall}
                    onClick={() => setFlagOpenWall(!flagOpenWall)}
                    onBlur={() => setFlagOpenWall(false)}
                    tabIndex={1}
                >
                    <span className="label">Цвет фасада</span>
                    <div className="border"/>
                    <div className="value">
                        <ColorFigure_ colorFigure={valueWall.value}/>
                        <span>{valueWall.label}</span>
                        <img src={arrow} className="arrow" alt="arrow"/>
                    </div>
                    <div className={"select_drop"}>
                        {colorsList.map((elem) =>
                            <div
                                className={elem.value === valueWall.value ? "active" : ""}
                                onClick={() => setValueWall(elem)}
                                key={elem.value}
                            >
                                <ColorFigure_ colorFigure={elem.value}/>
                                <span>{elem.label}</span>
                            </div>
                        )}
                    </div>
                </SelectParent_>
                <div className="hand_move"><img src={hand}/></div>
            </SelectColor_>
            <div className="bottom_green"/>
        </HouseSlide_>
    )
};
export default HouseSlide;
