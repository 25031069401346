import React from 'react';
import styled from 'styled-components';

import Main from "./components/Main";
import Bottom from "./components/Bottom";

const Preview_ = styled.div`
  padding-top: 33px;
  position: relative;
  padding-bottom: 110px;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(105.65deg, #315549 0%, #225040 102.64%);
  
  @media (max-width: 720px) {
    padding-bottom: 290px;
  }
`;

const Preview = ({scrollToPrice, refPreview}) => {
    return (
        <Preview_ ref={refPreview}>
            <div className="container">
                <Main scrollToPrice={scrollToPrice}/>
            </div>
            <Bottom/>
        </Preview_>
    );
};

export default Preview;