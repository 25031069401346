import React, { useEffect, useState } from 'react';
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import requests from "../../axios/requests";

import { History } from "../News/News";
import Pagination from "../News/Pagination/Pagination";

const Projects_ = styled.div`
  background: #F3F3F3;
  padding: 15px 15px 0 15px;

  .h1 {
    margin: 0 auto 50px auto;
    width: fit-content;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    color: #1D1D1D;
  }

  @media (max-width: 728px) {
    .h1 {
      margin: 0 auto 20px auto;
      font-size: 22px;
      line-height: 26px;
    }
  }
`;
const Content_ = styled.ul`
  display: flex;
  flex-wrap: wrap;
  min-height: 600px;

  li {
    margin: 0 19.5px 20px 0;
    max-width: 640px;
    width: 100%;
    transition: all .3s linear;
    cursor: pointer;

    .title {
      text-align: center;
      font-family: RfdBold, sans-serif;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: #1D1D1D;
    }

    &:nth-child(2n+2) {
      margin-right: 0;
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  @media (max-width: 1315px) {
    flex-direction: column;
    justify-content: center;
    li {
      margin: 0 auto 20px auto;

      &:nth-child(2n+2) {
        margin-right: auto;
      }
    }
  }
 
`;
const ImagePreview_ = styled.div`
  margin-bottom: 20px;
  border-radius: 24px;
  width: 100%;
  height: 373px;
  max-height: 373px;

  ${(props) => props.src && css`
    background: url("${props.src}") center center / cover;
  `};
  
  @media (max-width: 728px) {
    height: 270px;
  }
`;
const Projects = () => {
    const navigation = useNavigate();
    const [data, setData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    let apiProject = () => {
        requests.fetchProjects(currentPage, 6)
            .then(res => setData(res.data))
            .catch(err => console.error(err))
    }
    useEffect(() => {
        apiProject();
    }, [currentPage])
    return (
        <Projects_>
            <div className="container">
                <History parts={[
                  { path: '/', title: 'Главная' },
                  { title: "Проекты" }
                ]} />
                <div className="h1">Проекты</div>
                <Content_>
                    {data?.results?.length > 0 &&
                        <>
                            {data.results.map((elem) =>
                                <li
                                    key={elem.id}
                                    onClick={() => navigation(`/project-page/${elem.id}`)}
                                >
                                    <ImagePreview_
                                        src={elem.image}
                                    />
                                    <div className="title">
                                        {elem.title}
                                    </div>
                                    <div className="text">
                                        {elem.text}
                                    </div>
                                </li>
                            )}
                        </>
                    }
                </Content_>
                {data?.count > 6 &&
                    <Pagination
                        total={data.count}
                        setCurrentPage={setCurrentPage}
                        defaultPageSize={6}
                    />
                }
            </div>
        </Projects_>
    );
};

export default Projects;