import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled, { css } from 'styled-components';
import requests from "../../axios/requests";

import Pagination from "./Pagination/Pagination";
import arrow from '../../assets/icons/news_catalog/arrow.svg';

const News_ = styled.div`
  background: #F3F3F3;
  padding: 15px 15px 0 15px;

  .h1 {
    margin: 0 auto 50px auto;
    width: fit-content;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    color: #1D1D1D;

    @media (max-width: 728px) {
      margin: 0 auto 20px auto;
      font-size: 22px;
      line-height: 26px;
    }
  }

  @media (max-width: 930px) {
  }
`;
const Content_ = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 600px;

  li {
    margin: 0 20px 20px 0;
    max-width: 420px;
    width: 100%;
    cursor: pointer;
    transition: all .3s linear;

    .title {
      margin-bottom: 10px;
      font-family: RfdBold, sans-serif;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: var(--green);
    }

    .text {
      color: var(--text_green);
    }

    &:hover {
      transform: scale(1.01);
    }

    &:nth-child(3n+3) {
      margin-right: 0;
    }
  }

  @media (max-width: 1315px) {
    justify-content: center;
    li {
      &:nth-child(3n+3) {
        margin-right: 20px;
      }
    }
  }
  @media (max-width: 930px) {
    justify-content: center;
    li {
      margin-right: 0;

      .title {
        font-size: 16px;
        line-height: 19px;
      }

      .text {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
`;
const ImagePreview_ = styled.div`
  margin-bottom: 20px;
  border-radius: 24px;
  width: 100%;
  height: 300px;
  max-height: 300px;

  ${(props) => props.src && css`
    background: url("${props.src}") center center / cover;
  `};
  
  @media (max-width: 728px) {
    height: 220px;
  }
`;

export const History = ({parts = []}) => {
    const navigation = useNavigate();

    const CurrentHistory_ = styled.div`
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      div {
        color: var(--text_green);
        cursor: pointer;

        &:last-child {
          color: #F74F38;
        }
      }

      img {
        margin: 0 10px;
      }
    `;
    return (
        <CurrentHistory_>
            {
              parts.map((part, index) => 
              <>
                  {index > 0 && <img src={arrow} alt="arrow" key={`arrow-${index}`} />}
                  <div onClick={() => part.path && navigation(part.path)} key={`title-${index}`}>
                    { part.title }
                  </div>
              </>
              )
            }
        </CurrentHistory_>
    )
}
export const News = () => {
    const navigation = useNavigate();

    const [newsData, setNewsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    let apiNews = () => {
        requests.fetchNews(currentPage, 9)
            .then(res => setNewsData(res.data))
            .catch(err => console.error(err))
    }
    useEffect(() => {
        apiNews();
    }, [currentPage])
    return (
        <News_>
            <div className="container">
                <History parts={[
                  { path: '/', title: 'Главная' },
                  { title: "Новости" },
                ]} />
                <div className="h1">Новости</div>
                <Content_>
                    {newsData?.results?.length > 0 &&
                        newsData.results.map((elem) =>
                            <li
                                key={elem.slug}
                                onClick={() => navigation(`/news-page/${elem.slug}`)}
                            >
                                <ImagePreview_
                                    src={elem.image}
                                />
                                <div className="title">
                                    {elem.title}
                                </div>
                                <div className="text">
                                    {elem.text}
                                </div>
                            </li>
                        )
                    }
                </Content_>
                {newsData?.count && newsData?.count > 9 &&
                    <Pagination
                        total={newsData.count}
                        setCurrentPage={setCurrentPage}
                        defaultPageSize={9}
                    />
                }
            </div>
        </News_>
    );
};

