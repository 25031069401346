import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import styled, { css } from "styled-components";
import requests from "../../axios/requests";
import { History } from "../News/News";

const ProjectPage_ = styled.div`
  background: #F3F3F3;

  .h1 {
    margin: 0 auto 50px auto;
    width: fit-content;
    max-width: 615px;
    text-align: center;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
    color: #1D1D1D;
  }

  .text {
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
    text-align: center;
    font-size: 18px;
    color: var(--green)
  }

  .border {
    margin: 80px 0 50px 0;
    width: 100%;
    height: 1px;
    background: var(--text_green);
  }

  @media (max-width: 920px) {
    padding: 0 15px;
  }
  @media (max-width: 728px) {
    .h1 {
      margin: 0 auto 30px auto;
      font-size: 22px;
      line-height: 26px;
    }
    .border {
      margin: 40px 0 50px 0;
    }
  }
`;
const Elem_ = styled.div`
  max-width: 800px;
  width: 100%;
  margin: 0 auto;

  img {
    max-width: 800px;
    width: 100%;
  }
`;
const NewsImg_ = styled.div`
  margin: -20px auto 0 auto;
  max-width: 800px;
  width: 100%;
  height: 480px;
  border-radius: 24px;

  ${(props) => props.src && css`
    background: url("${props.src}") center center / cover;
  `};
  
  @media (max-width: 728px) {
    height: 380px;
  }
  @media (max-width: 450px) {
    height: 260px;
  }
`;
const ProjectPage = () => {
    const params = useParams();
    const [currentElem, setCurrentElem] = useState({});

    let fetchApiId = () => {
        requests.fetch_id_project(params.id)
            .then(res => setCurrentElem(res.data))
            .catch(err => console.error(err))
    };

    useEffect(() => {
        fetchApiId();
    }, [params.slug])
    return (
        <ProjectPage_>
            <div className="container">
                <History parts={[
                  { path: '/', title: 'Главная' },
                  { path: '/projects', title: "Проекты" },
                  { title: currentElem?.title }
                ]} />
                <Elem_>
                    <div className="h1">{currentElem?.title}</div>
                    <NewsImg_ src={currentElem?.image}/>
                    <div dangerouslySetInnerHTML={{__html: currentElem?.description}}></div>
                </Elem_>
                <div className="border"/>
            </div>
        </ProjectPage_>
    );
};

export default ProjectPage;