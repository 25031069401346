import React, { useState } from 'react';
import requests from "../../../../axios/requests";

import { Modal, message as Message } from "antd";
import { ButtonMore } from "../../../Catalog/components/ListElem";

import close from "../../../../assets/icons/calculate/close.svg";
import './FeedbackModal.scss';

const FeedbackModal = ({open, setOpen}) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const flagDisabled = !name || !phone || phone.length < 11 || !message;

    let requestApplication = () => {
        if (flagDisabled) return
        if (phone[0] !== "+")
            return Message.error("Неверный формат номера. Пример +71234567890");

        requests.sendApplication({name, phone, message})
            .then(() => {
                Message.success("Ваша заявка приянта. С вами свяжутся.", 8)
                setName("");
                setPhone("");
                setMessage("");
                setOpen();
            })
            .catch(err => console.error(err))
    }
    return (
        <Modal
            open={open}
            footer={null}
            className={"modal_feedback"}
            onCancel={setOpen}
            centered={window.innerWidth > 728}
            closable={false}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.70)"}}
        >
            <div className="title">
                <div className="h1">Заказать звонок</div>
                <img
                    className="close"
                    onClick={() => setOpen()}
                    src={close}
                    alt="close"
                />
            </div>
            <div className="input_parent">
                <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={"Имя"}
                    autoFocus="autoFocus"
                />
            </div>
            <div className="input_parent">
                <input
                    value={phone}
                    placeholder={"+71234567890"}
                    onChange={(e) => {
                        let value = e.target.value || "";
                        value = value.replace(/[^0-9+]/g, "");
                        if (value.length > 14)
                            return
                        setPhone(value)
                    }}
                />
            </div>
            <textarea
                className="text_area"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={4}
                placeholder={"Сообщение"}
            />
            <ButtonMore
                label={"Отправить"}
                handleOpen={requestApplication}
                margin={"auto"}
                disabled={flagDisabled}
            />
        </Modal>
    );
};

export default FeedbackModal;