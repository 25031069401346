import axios from "axios";
import { message } from "antd";

const token = localStorage.getItem("token");
const defaultApiSetting = {
    /*baseURL: 'https://185.117.153.245:8003.skillometer.org/api/v1/',*/
    baseURL: 'https://api.its96.ru/api/v1/',
    headers: {
        "Content-Type": "application/json",
        /* "Access-Control-Allow-Origin": "*",*/
        ...(token ? {"Authorization": `Token ${token}`} : {})
    }
};

const apiClient = axios.create(defaultApiSetting);
const responseSuccessHandler = response => response;
const responseErrorHandler = error => {
    if (error?.response?.data?.phone)
        message.error(error.response.data.phone[0]);
    return Promise.reject(error)
};

apiClient.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);

export default {
    fetchNews(page, size) {
        return apiClient.get(`landing/news/?page=${page}&page_size=${size}`)
    },
    fetch_id_news(id) {
        return apiClient.get(`landing/news/${id}/`)
    },
    fetchCatalog(params) {
        return apiClient.get(`landing/category/`, {params})
    },
    fetch_id_catalog(id, params) {
        return apiClient.get(`landing/category/${id}/`, {params})
    },
    fetch_id_subcategory(id, params) {
        return apiClient.get(`landing/subcategory/${id}/`, {params})
    },
    fetch_id_product(id) {
        return apiClient.get(`landing/product/${id}/`)
    },
    fetchProjects(page, size) {
        return apiClient.get(`landing/project/?page=${page}&page_size=${size}`)
    },
    fetch_id_project(id) {
        return apiClient.get(`landing/project/${id}/`)
    },
    fetchDocs(page, size) {
        return apiClient.get(`landing/document/?page=${page}&page_size=${size}`)
    },
    sendApplication(request) {
        return apiClient.post(`landing/application/`, request)
    },
}
