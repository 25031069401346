import React from 'react';
import styled from "styled-components";

const Checkbox_ = styled.div`
  .form-group {
    display: block;
    color: white;
    font-family: RfdRegular, sans-serif;
    font-size: 16px;
    line-height: 19px;
  }

  .form-group input {
    padding: 0;
    margin-bottom: 0;
    height: initial;
    width: initial;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  .form-group label:before {
    content: '';
    margin-right: 10px;
    -webkit-appearance: none;
    background: #1D1D1D;
    width: 25px;
    height: 25px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 8px;
  }

  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 9px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  @media (max-width: 728px) {
    margin-bottom: 15px;
  }
`;
const Checkbox = ({value, setValue, label, id = 'html'}) => {
    return (
        <Checkbox_>
            <div className="form-group">
                <input
                    type="checkbox"
                    id={id}
                    checked={value}
                    onChange={(e) => setValue(e.target.checked)}
                />
                <label htmlFor={id}>{label}</label>
            </div>
        </Checkbox_>
    );
};

export default Checkbox;