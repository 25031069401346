import React, { useState } from 'react';
import calculate_preview from '../../../../assets/icons/calculate/calculate_preview.png';
import styled from "styled-components";
import { ButtonMore } from "../../../Catalog/components/ListElem";
import CalculateModal from "./components/CalculateModal";

const CalculateParent_ = styled.div`
  position: relative;
  overflow: hidden;
  height: 605px;
  background: rgba(0, 0, 0, 0.46);

  .top_black {
    position: absolute;
    width: calc(100% + 30px);
    height: 60px;
    top: -30px;
    left: -5px;
    transform: rotate(-2deg);
    background: #171717;
    z-index: 0;
  }

  .black_back {
    position: relative;
    width: 100%;
    height: 605px;
    background: rgba(29, 29, 29, 0.93);
    z-index: -3;
  }

`;
const FixedPhoto_ = styled.div`
  position: relative;
  z-index: -3;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 168px;
    left: -20px;
    height: 605px;
    width: calc(100% + 20px);
    background: rgba(0, 0, 0, 0.46);
    overflow: hidden;
  }

  .content::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    background-image: url(${calculate_preview});
    background-size: cover;
    width: 100%;
    height: 68vh;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    z-index: 1;
  }
`;
const Info_ = styled.div`
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 568px;
  width: 100%;
  color: white;
  /*transform: rotate(1.8deg);*/
  z-index: 2;

  .title {
    margin-bottom: 20px;
    text-align: center;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;
  }

  .text {
    margin: 0 auto 20px auto;
    max-width: 423px;
    width: 100%;
    text-align: center;
    font-size: 22px;
    line-height: 26px;
  }

  .catch_click {
    margin: 0 auto;
    max-width: 310px;
    width: 100%;
  }
`;
const Calculate = ({setOpen}) => {
    return (
        <>
            <CalculateParent_ >
                <div className="top_black" />
                <div className="black_back"/>
                <Info_>
                    <div className="title">
                        Онлайн калькулятор стоимости утеплителя
                    </div>
                    <div className="text">
                        Калькулятор расчета количества
                        утеплителя для стен и фундаментов
                    </div>
                    <div
                        className="catch_click"
                        onClick={() => setOpen()}
                    >
                        <ButtonMore
                            maxWidth={310}
                            label={"Произвести расчет"}
                            margin={"auto"}
                        />
                    </div>
                </Info_>
                <CalculateModal/>
            </CalculateParent_>
            <FixedPhoto_>
                <div className="content"/>
            </FixedPhoto_>
        </>
    );
};

export default Calculate;