import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import CountUp from "react-countup";

const Bottom_ = styled.div`
  position: absolute;
  padding: 30px 20px 50px 20px;
  bottom: -31px;
  left: -2px;
  width: calc(100% + 5px);
  transform: rotate(-2deg);
  background: #1D1D1D;
  @media (max-width: 720px) {
    bottom: -25px;
  }
`;
const List_ = styled.ul`
  display: flex;
  align-items: center;
  transform: rotate(2deg);
  width: fit-content;

  li {
    margin-right: 30px;
    width: fit-content;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 720px) {
      margin-right: 0;
      max-width: 250px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .title {
    font-family: RfdBold, sans-serif;
    min-width: 111px;
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    color: white;
    opacity: 0;
    transition: all .6s linear;

    &.show {
      opacity: 1;
    }

    @media (max-width: 720px) {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }

  .text {
    margin-top: 5px;
    color: var(--text_green);
    @media (max-width: 720px) {
      font-size: 13px;
      line-height: 16px;
      text-align: center;
    }
  }

  @media (max-width: 720px) {
    margin: 0 auto;
    flex-direction: column;
  }
`;
const Bottom = () => {
    const [showCounts, setShowCounts] = useState(false);

    useEffect(() => {
        setTimeout(() => setShowCounts(true), 500)
    }, [])
    return (
        <Bottom_>
            <div className="container">
                <List_>
                    <li>
                        <div
                            className={showCounts ? "title show" : "title"}
                        >
                            <CountUp end={27} start={null} duration={3.4} delay={1.5}/>
                            {" "}
                            лет
                        </div>
                        <div className="text">На рынке более 27 лет</div>
                    </li>
                    <li>
                        <div
                            className={showCounts ? "title show" : "title"}
                        >
                            <CountUp start={null} end={5000} separator={"'"} duration={2} delay={1.5}/>
                            +
                        </div>
                        <div className="text">Объектов из наших материалов</div>
                    </li>
                    <li>
                        <div
                            className={showCounts ? "title show" : "title"}
                        >
                            <CountUp start={null} end={5} duration={5} delay={1.5}/>
                            {" "}
                            направлений производства
                        </div>
                        <div className="text">Широкий ассортимент производства материалов</div>
                    </li>
                    <li>
                        <div
                            className={showCounts ? "title show" : "title"}
                        >
                            <CountUp start={null} separator={"'"} end={500000} duration={2} delay={1.5}/>
                            +
                            {" "}

                        </div>
                        <div className="text">Клиентов</div>
                    </li>
                </List_>
            </div>
        </Bottom_>
    );
};

export default Bottom;