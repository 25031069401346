import React from 'react';
import styled from "styled-components";
import {Pagination as PaginationAntd} from "antd";

const Pagination_ = styled.div`
  margin: 30px auto;
  width: fit-content;

  .ant-pagination-item {
    margin-right: 5px;
    background-color: transparent;
    border: none;

    a {
      font-family: RfdBold, sans-serif;
      padding: 5px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: white;
      border-radius: 8px !important;
      background-color: var(--text_green);

      &:hover {
        color: #4096ff;
      }
    }

    &-active {
      a {
        background-color: var(--green);

        &:hover {
          color: #4096ff;
        }
      }

      border-color: transparent;
    }
  }

  li.ant-pagination-options {
    display: none;
  }

  li.ant-pagination-prev,
  li.ant-pagination-next {
    display: none;
  }

  span.ant-pagination-item-ellipsis,
  span.anticon.anticon-double-right.ant-pagination-item-link-icon,
  span.anticon.anticon-double-left.ant-pagination-item-link-icon {
    color: #61687c;
  }
`;
const Pagination = ({total, setCurrentPage, defaultPageSize}) => {
    let itemRender = (_, type, originalElement) => {
        if (type === "prev")
            return null;
        if (type === "next") {
            return;
        }
        return originalElement;
    };
    return (
        <Pagination_>
            <PaginationAntd
                itemRender={itemRender}
                defaultCurrent={1}
                defaultPageSize={defaultPageSize}
                total={total}
                onChange={(e) => setCurrentPage(e)}
                showTitle={false}
            />
        </Pagination_>
    );
};

export default Pagination;