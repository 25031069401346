import React from "react";
import more from "../../../assets/icons/catalog/more.svg";
import styled, { css } from "styled-components";
import catalog_1 from '../../../assets/icons/catalog/catalog_1.png';

import helpers, { currency } from '../../../utils/helpers';

const ButtonMore_ = styled.div`
  margin-top: auto;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: white;
  background: linear-gradient(91.37deg, #F74F38 0%, #E3371F 100%);
  border-radius: 16px;
  user-select: none;
  cursor: pointer;

  span {
    margin: 2px 10px 0 0;
  }

  ${(props) => props.backColor && css`
    background: ${props.backColor};
  `};
  ${(props) => props.maxWidth && css`
    max-width: ${props.maxWidth}px;
  `};
  ${(props) => props.margin && css`
    margin: 0 auto;
  `};
  ${(props) => props.marginTop && css`
    margin-top: ${props.marginTop};
  `};
  ${(props) => props.disabled && css`
    max-width: ${props.disabled}px;
    background: rgba(227, 55, 31, .5);
    cursor: auto;
  `};

  @media (max-width: 720px) {
    padding: 9px;
    border-radius: 14px;
  }
`;
const Product_ = styled.li`
  flex: 0 0 calc(25% - 20px);
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .price-wrapper {
    display: flex;
    gap: 20px;
  }

  .name, .price {
    margin: 20px auto;
    width: fit-content;
    font-family: RfdBold, sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #1D1D1D;
    word-break: break-word;
    hyphens: auto;

    @media (max-width: 720px) {
      margin: 15px auto;
      font-size: 16px;
      line-height: 19px;
    }
  }
  
  @media (max-width: 1320px) {
    max-width: 420px !important;
    flex: 0 0 calc(50% - 20px);
  }

  @media (max-width: 720px) {
    flex: 0 0 calc(100% - 20px);
  }
`;
const Preview_ = styled.div`
  aspect-ratio: 1;  
  border-radius: 24px;
 
  transition: background-size .5s ease-in;
  -moz-transition: background-size .5s ease-in;
  -ms-transition: background-size .5s ease-in;
  -o-transition: background-size .5s ease-in;
  -webkit-transition: background-size .5s ease-in;
  ${(props) => props.src && css`
    background: url(${props.src});
    background-repeat: no-repeat;
    background-size: 105% 105%;

    &:hover {
      background-size: 110% 110% !important;
    }
  `};
`;
export const ButtonMore = ({label, maxWidth, margin, handleOpen, marginTop = "", backColor = "", disabled = false}) => {
    return (
        <ButtonMore_
            maxWidth={maxWidth}
            margin={margin}
            marginTop={marginTop}
            disabled={disabled}
            backColor={backColor}
            onClick={() => {
                if (typeof handleOpen === "function")
                    handleOpen()
            }}
        >
            <span>{label}</span>
            <img src={more} alt="more"/>
        </ButtonMore_>
    )
}
export const Product = ({id, image, name, price, type, handleOpen}) => {
    return (
        <Product_ onClick={() => handleOpen(id)}>
            <Preview_ src={image}/>
            <div className="price-wrapper">
              <div className="name">{helpers.brChanger(name)}</div>
              <div className="price">{currency(price)} / {type}</div>
            </div>
            <ButtonMore
                label={"Подробнее"}
                handleOpen={() => handleOpen(id)}
            />
        </Product_>
    )
}