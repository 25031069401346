import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import requests from "../../../../axios/requests";

import { CatalogList } from "../../../Catalog/components/CatalogList";
import { ButtonMore } from "../../../Catalog/components/ListElem";

const Catalog = () => {
  const navigation = useNavigate();
    const [catalogData, setCatalogData] = useState({});

    useEffect(() => {
      requests.fetchCatalog({ limit: 5, page: 1 })
      .then(res => {
          if (res.data?.length) {
              res.data.forEach((elem, indx) => (
                elem.size = (indx === 0 || indx === 1) ? "big" : "small"
              ))
          }
          setCatalogData(res.data)
      })
      .catch(err => console.error(err))
    }, [])

    return (
        <CatalogList
          data={catalogData}
          currentPage={1}
          handleOpen={(id) => navigation(`/catalog/${id}`)}
          pagination={
            <ButtonMore
              label={"Перейти в полный Каталог"}
              maxWidth={420}
              handleOpen={() => navigation("/catalog")}
              margin={"auto"}
              marginTop={"20px"}
            />
          }
        />
    );
};

export default Catalog;