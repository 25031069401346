import React, {  useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import slider_1 from '../../../../../assets/icons/preview/slider_1.png';
import slider_2 from '../../../../../assets/icons/preview/slider_2.png';
import slider_3 from '../../../../../assets/icons/preview/slider_3.png';

import Banner from "./Banner";

const position_1 = 0;
const position_2 = window.innerWidth > 515 ? "60px" : "30px";
const position_3 = window.innerWidth > 515 ? "110px" : "55px";

const Main_ = styled.div`
  margin-bottom: 60px;
  display: flex;
  position: relative;
  justify-content: space-between;

  .slider_preview {
    position: absolute;
    height: 705px;
    width: 900px;
    top: 100px;
    right: 0;
    z-index: 5;
    @media (max-width: 1300px) {
      width: 350px;
      top: 310px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .slider_elem {
    /*margin-top: -50px;*/
    margin: 0 30px;

    &-first {
      margin-top: 50px;
      @media (max-width: 1300px) {
        margin-top: 25px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    &-second {
      margin-left: 20px;

      img {
        max-width: 880px;
      }

      @media (max-width: 1300px) {
        margin-left: 0;
      }
    }
  }

  .slick-slide div {
    outline: none;
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;
const Info_ = styled.div`
  max-width: 426px;
  width: 100%;
  @media (max-width: 1300px) {
    margin: 0 auto;
  }
`;
const Title_ = styled.div`
  margin: 98px 0 20px 0;
  max-width: 350px;
  width: 100%;
  font-size: 44px;
  line-height: 53px;
  color: white;
  font-family: RfdBold, sans-serif;

  @media (max-width: 1300px) {
    margin: 20px auto 20px auto;
    max-width: 260px;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
  }
`;
const Text_ = styled.div`
  margin-bottom: 20px;
  width: 100%;
  color: var(--text_green);
  font-size: 20px;
  line-height: 24px;
  @media (max-width: 1300px) {
    font-size: 13px;
    line-height: 16px;
    text-align: center;
  }
`;
const Button_ = styled.div`
  padding: 17px 15px 13px 15px;
  max-width: 260px;
  width: 100%;
  color: white;
  text-align: center;
  background: linear-gradient(91.37deg, #F74F38 0%, #E3371F 100%);
  border-radius: 16px;
  cursor: pointer;
  transition: all .3s linear;
  margin-bottom: 130px;

  &:hover {
    background: var(--text_green);
    transition: all .3s linear;
  }

  @media (max-width: 1300px) {
    max-width: 180px;
    margin: 0 auto 20px auto;
  }
`;
const ImagesNew_ = styled.div`
  margin-right: 100px;
  display: flex;
  position: relative;
  max-width: 551px;
  height: 551px;
  width: 100%;

  .slide_parent {
    position: absolute;
    width: 551px;
    height: 551px;
    border-radius: 50%;
    overflow: hidden;
    transition: all .5s linear;
  }

  .image {
    position: absolute;
    top: 0;
    width: 600px;
    height: inherit;
    transition: all 5s linear;
    &.first {
      background: url(${slider_1}) no-repeat;
      background-size: 100% 100%;
      background-position-x: 0;
      /*background-size: contain;*/
    }

    &.second {
      background: url(${slider_2}) no-repeat;
      background-size: 100% 100%;
      /*background-size: contain;*/
    }

    &.third {
      background: url(${slider_3}) no-repeat;
      background-size: 100% 100%;
      /*background-size: contain;*/
    }
  }

  ${props => props.index === 0 && css`
    .slide_parent:nth-child(1) {
      z-index: 3;
      left: ${position_1};
    }

    .slide_parent:nth-child(2) {
      left: ${position_2};
      z-index: 2;
    }

    .slide_parent:nth-child(3) {
      left: ${position_3};
      z-index: 1;
    }

    .first {
      transform: scale(1.1);
    }

    .second {
      transition-duration: .3s;
    }

    .third {
      transition-duration: .3s;
    }
  `};
  ${props => props.index === 1 && css`
    .slide_parent:nth-child(1) {
      z-index: 2;
      left: ${position_2};
    }

    .slide_parent:nth-child(2) {
      left: ${position_1};
      z-index: 3;
    }

    .slide_parent:nth-child(3) {
      left: ${position_3};
      z-index: 1;
    }

    .first {
        /*z-index: 2;
      left: ${position_2};*/
      transition-duration: .3s;
    }

    .second {
        /*      left: ${position_1};
      z-index: 3;*/
      transform: scale(1.1);
    }

    .third {
        /*left: ${position_3};
      z-index: 1;*/
      transition-duration: .3s;
    }
  `};
  ${props => props.index === 2 && css`
    .slide_parent:nth-child(1) {
      z-index: 2;
      left: ${position_2};
    }

    .slide_parent:nth-child(2) {
      left: ${position_3};
      z-index: 1;
    }

    .slide_parent:nth-child(3) {
      left: 0;
      z-index: 3;
    }

    .first {
        /*   z-index: 2;
      left: ${position_2};*/
      transition-duration: .3s;
    }

    .second {
        /*  left: ${position_3};
      z-index: 1;*/
      transition-duration: .3s;
    }

    .third {
      /*  left: 0;
        z-index: 3;*/
      transform: scale(1.1);
    }
  `};
  @media (max-width: 1300px) {
    margin: 0 auto;
    max-width: 350px;
    height: 350px;

    .image {
      width: 350px;
      height: 350px;
    }

    .slide_parent {
      margin-left: -50px;
      width: 350px;
      height: 350px;
    }
  }
  
  @media (max-width: 515px) {
    .slide_parent {
      margin-left: -20px;
    }
  }
  
  @media (max-width: 445px) {
    margin: 0 auto;
    max-width: 250px;
    height: 250px;

    .image {
      width: 250px;
      height: 250px;
    }

    .slide_parent {
      margin-left: -20px;
      width: 250px;
      height: 250px;
    }
  }
  
  @media (max-width: 380px) {
    .slide_parent {
      margin-left: -30px;
    }
  }
`;
const Main = () => {
    const [index, setIndex] = useState(null);
    const navigation = useNavigate();

    const timeoutRef = React.useRef(null);
    const delay = 5000;
    let resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }
    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () => setIndex((prevIndex) => prevIndex === 2 ? 0 : prevIndex + 1),
            delay
        );
        return () => {
            resetTimeout();
        };
    }, [index]);
    React.useEffect(() => {
        setIndex(0)
    }, []);
    return (
        <Main_>
            <Info_>
                <Title_>
                    Производство и реализация строительных материалов
                </Title_>
                <Text_>
                    Менеджеры нашей компании наиболее полно и достоверно
                    проконсультируют по выбору строительных материалов
                </Text_>
                <Button_
                    onClick={() => navigation('/catalog')}
                >
                    <span>Перейти в Каталог</span>
                </Button_>
                <Banner />
            </Info_>
            <ImagesNew_ index={index}>
                <div className="slide_parent">
                    <div className={`image first`}>{/*<img src={slider_1_new} alt="slider"/>*/}</div>
                </div>
                <div className="slide_parent">
                    <div className={`image second`}>{/*<img src={slider_2_new} alt="slider"/>*/}</div>
                </div>
                <div className="slide_parent">
                    <div className={`image third`}>{/*<img src={slider_3_new} alt="slider"/>*/}</div>
                </div>
            </ImagesNew_>
        </Main_>
    );
};

export default Main;