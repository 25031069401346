import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import requests from "../../../../axios/requests";

import { ButtonMore } from "../../../Catalog/components/ListElem";
import SliderItem from "./components/SliderItem";

import slide_1 from '../../../../assets/icons/news/slide_1.png';
import slide_2 from '../../../../assets/icons/news/slide_2.png';
import slide_3 from '../../../../assets/icons/news/slide_3.png';
import arrow from '../../../../assets/icons/news/arrow.svg';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const News_ = styled.div`
  padding: 50px 0 140px 0;
  background: #F3F3F3;

  .h1 {
    margin: 0 auto 50px auto;
    width: fit-content;
    font-family: RfdBold, sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 46px;

    @media (max-width: 1320px) {
      margin: 0 auto 30px auto;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
    }
  }

  .slick-slider {
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    @media (max-width: 1320px) {
      margin-left: 0;
    }
  }

  .slick-list {
    /*padding: 0 20px !important;*/
    @media (max-width: 728px) {
      padding: 0 !important;
    }
  }

  .line {
    margin: 20px auto;
    height: 1px;
    background: var(--text_green);
    max-width: 1300px;
    width: 100%;
    @media (max-width: 1320px) {
      margin-left: 0;
    }
  }

  .nav_slider {
    margin: 0 auto;
    max-width: 1300px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .arrows {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        cursor: pointer;

        &:last-child {
          margin-left: 6px;
          transform: rotate(180deg);
        }
      }
    }

    @media (max-width: 1320px) {
      margin-left: 0;
    }
  }

  @media (max-width: 1320px) {
    padding-left: 15px;
  }
`;
const News = () => {
    const sliderRef = React.useRef(null);
    const navigation = useNavigate();
    const [newsData, setNewsData] = useState([]);

    let currencyLengthNews = () => {
        let count = 0;
        if (newsData.length > 3) {
            count = 3;
        } else count = newsData.length;
        if (count > 2 && window.innerWidth < 1060)
            count = 2;
        if (count !== 0 && window.innerWidth < 728)
            count = 1;
        return count;
    }
    let settings = {
        /*centerMode: window.innerWidth > 1205,*/
        /*centerMode: window.innerWidth > 1205,*/
        touchMove: window.innerWidth <= 728,
        centerPadding: window.innerWidth > 1205 && '70px',
        maxWidth: 1300,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: currencyLengthNews(),
        slidesToScroll: 1,
        arrows: false,
    }
    let apiNews = () => {
        requests.fetchNews(1, 12)
            .then(res => setNewsData(res.data.results))
            .catch(err => console.error(err))
    }
    useEffect(() => {
        apiNews();
    }, [])
    return (
        <News_>
            <div className="h1">Новости</div>
            {newsData.length > 0 &&
                <Slider ref={sliderRef} {...settings}>
                    {newsData.map((elem) =>
                        <div key={elem.title}>
                            <SliderItem
                                src={elem.image}
                                title={elem.title}
                                text={elem.text}
                                slug={elem.slug}
                            />
                        </div>
                    )}
                </Slider>
            }
            <div className="line"/>
            <div className="nav_slider">
                <ButtonMore
                    label={"Показать все новости"}
                    maxWidth={310}
                    handleOpen={() => navigation("/news")}
                />
                <div className="arrows">
                    <img src={arrow} onClick={() => sliderRef?.current?.slickPrev()}/>
                    <img src={arrow} onClick={() => sliderRef?.current?.slickNext()}/>
                </div>
            </div>
        </News_>
    );
};

export default News;