import React, { useEffect, useState }  from 'react';
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import requests from "../../axios/requests";
import { History } from "../News/News";

import { Product } from "./components/Product";

import './SubcategoryProducts.scss';

const SubcategoryProducts_ = styled.div`
  background: #F3F3F3;
  padding: 15px 15px 0 15px;
`;

const Content_ = styled.ul`
  display: flex;
  flex-flow: row wrap;
  gap: 20px;

  @media (max-width: 1315px) {
    justify-content: center;
  }
`;

export const SubcategoryProducts = () => {
    const params = useParams();

    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (params.id)
            requests.fetch_id_subcategory(params.id)
                .then(res => (
                    setData(res.data)
                ))
                .catch(err => console.error(err))
        else setData({});
    }, [params.id])

    return (
        <SubcategoryProducts_>
            <div className="container">
                <History parts={[
                  { path: '/', title: 'Главная' },
                  { path: '/catalog', title: "Каталог" },
                  { path: `/catalog/${data?.results?.parent?.id}`, title: data?.results?.parent?.name },
                  { title: data?.results?.name }
                ]} />
                <div className="catalog_page">
                    { data?.results?.name &&
                    <div className="h1">
                        {data?.results.name}
                    </div>
                    }
                    <Content_>
                        { data?.results?.products?.map((elem) =>
                        <Product
                            key={elem.id}
                            id={elem.id}
                            image={elem.images?.[0]?.image}
                            name={elem.name}
                            price={elem.price}
                            type={elem.type}
                            description={elem.description}
                            handleOpen={() => navigate(`/product/${elem.id}`)}
                        />
                        )}
                    </Content_>
                </div>
            </div>
        </SubcategoryProducts_>
    );
};
