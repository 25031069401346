import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import requests from "../../axios/requests";

import { History } from "../News/News";
import { CatalogList } from "./components/CatalogList";
import Pagination from "../News/Pagination/Pagination";

export const CategorySubcategories = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [data, setData] = useState({});

    useEffect(() => {
        if (params.id)
            requests.fetch_id_catalog(params.id)
                .then(res => (
                    res.data?.results?.subcategories?.forEach(
                        elem => (
                            elem.size = "small"
                        )
                    ),
                    setData(res.data)
                ))
                .catch(err => console.error(err))
        else setData({})
    }, [params.id]);

    return (
            <CatalogList
                history={<History parts={[
                    { path: '/', title: 'Главная' },
                    { path: '/catalog', title: "Каталог" },
                    { title: data?.results?.name }
                  ]} />}
                title={data?.results?.name}
                data={data?.results?.subcategories}
                currentPage={1}
                handleOpen={(id) => navigate(`/catalog/${params.id}/${id}`)}
            />
    );
};
